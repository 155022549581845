/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import PatientDataStorage from "./DataService/DataService";

import Home from './home/Home';
import About from './misc/about';
import {MPYHeader, MPYFooter} from './shared/PageHeaderFooter';
import AdminProfile from './admin/AdminProfile';
import CareGiverProfile from './caregiver/CareGiverProfile';
import PatientDetails from './home/PatientDetails';
import Login, {Logout} from './misc/login';
import ValidicDevices from './misc/validicDevices';
import Options from './options/Options';
import HeatmapDisplay from "./visualizations/HeatmapDisplay";
import {URLS} from './shared/urls';
import { AppStrings } from './shared/strings';
import CaregiverServices from './DataService/CareGiverService';
import UnusualEventsReport from './reports/UnusualEventsReport';
import DeviceEventReport from './reports/DeviceEventsReport';
import RxDeviceDataReport from "./reports/RxDeviceDataReport";
import BatteryLevelReport from "./reports/BatteryLevelReport";
import HomeLifeReport from "./reports/HomeLifeReport";

import './app.scss';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { loggedIn: false, userType: undefined, caregiverId: undefined, modalToggle: false };
        this.handleLoginStateChange = this.handleLoginStateChange.bind(this);
    }

    componentDidMount() {
        // get our data
        let loggedIn = ((CaregiverServices.getLoginInfo("app", this.handleLoginStateChange).caregiverId) !== undefined);
        this.setState({loggedIn: loggedIn});
    }

    componentDidUpdate() {
        console.log(`app: componentDidUpdate`)
    }

    handleLoginStateChange(loginInfo) {
        let loggedIn = (loginInfo.caregiverId !== undefined);
        this.setState({loggedIn: loggedIn, caregiverId: loginInfo.caregiverId, userType: loginInfo.userType});
    }

    render() {
        let modal = PatientDataStorage.isDisplayTimeoutPopped() ? this.buildModal() : '';
        return (
            <div className="App">
                <Router>
                    <div>
                        <MPYHeader />

                        <Route exact path={URLS.root} component={Login}/>
                        <Route path={URLS.adminProfile} component={AdminProfile}/>
                        <Route path={URLS.caregiverProfile} component={CareGiverProfile}/>
                        <Route path={URLS.about} component={About}/>
                        <Route exact path={URLS.patientCardDetails} component={PatientDetails}/>
                        <Route path={URLS.login} component={Login}/>
                        <Route exact path={URLS.patientAlertSetting} component={Options}/>
                        <Route exact path={URLS.heatmapURL} component={HeatmapDisplay}/>
                        <Route exact path={URLS.home} component={Home}/>
                        <Route path={URLS.logout} component={Logout}/>
                        <Route exact path={URLS.unusualEvents} component={UnusualEventsReport}/>
                        <Route exact path={URLS.rxDeviceDetail} component={RxDeviceDataReport} />
                        <Route exact path={URLS.deviceDetail} component={DeviceEventReport} />
                        <Route exact path={URLS.batteryLevels} component={BatteryLevelReport} />
                        <Route exact path={URLS.homeLifeReport} component={HomeLifeReport} />
                        <Route exact path={URLS.validicDeviceSupport} component={ValidicDevices} />
                        {/*<Route component={NoMatch} />*/}
                    </div>
                </Router>
                <MPYFooter/>
                {modal}
            </div>
        );
    }

    buildModal() {
        let hide = () => {
            this.setState({modalToggle: !this.state.modalToggle});
        };

        return (
        <div className="static-modal">
            <Modal show={true} backdrop={true}>
                <Modal.Header>
                    <Modal.Title>{AppStrings.appName}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{AppStrings.inactivityLogout}</Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => {PatientDataStorage.setDisplayTimeoutPopped(false); hide(); }} variant="primary">{AppStrings.ok}</Button>
                </Modal.Footer>
            </Modal>
        </div>);
    }
}

export default App;
