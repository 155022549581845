/*******
 * Copyright 2017 - EmPowerYu, inc.
 *
 */

//
// These lists of domains are only needed if you are running a client system without
// your own local backend. Then you would change the domain selection from "home" to
// the server who's backend you want to use. Or as in some tresting configurations (such as rk),
// where it won't work otherwise.
//
const domains = {
    home: {domain: "/EPYu", postfix: "/"},
    localrich: {domain: "https://localhost:82", postfix: ""},
    devrichard: {domain: "https://devrichard.empoweryu.com/EPYu", postfix: "/"},
    devlee: {domain: "https://devlee.empoweryu.com/EPYu", postfix: "/"},
    devkirk: {domain: "https://devkirk.empoweryu.com/EPYu", postfix: "/"},
    demo: {domain: "https://demo.empoweryu.com/EPYu", postfix: "/"},
    beta: {domain: "https://beta.empoweryu.com/EPYu", postfix: "/"},
    rk: {domain: "https://192.168.1.100/EPYu", postfix: "/"}    // rk's home system
};

function getURLS () {
    let urlInfo = domains.home;  // Only change this if needed!
    let _URLS = {
        // PHP server URLs must end with a '/'  !!!!!!!!
        getPatientList:                 urlInfo.domain+'/getPatientList' + urlInfo.postfix,
        getpatientmonitoringoptions:    urlInfo.domain+'/getPatientMonitoringOptions' + urlInfo.postfix,
        setpatientmonitoringoptions:    urlInfo.domain+'/setPatientMonitoringOptions' + urlInfo.postfix,
        getpatientdetails:              urlInfo.domain+'/getPatientDetails' + urlInfo.postfix,
        validateusercredentials:        urlInfo.domain+'/validateLoginCredentials' + urlInfo.postfix,
        logoutUser:                     urlInfo.domain+'/logoffUser' + urlInfo.postfix,
        getSleepHMapData:               urlInfo.domain+'/getSleepVisualizationData' + urlInfo.postfix,
        getMealsHMapData:               urlInfo.domain+'/getMealVisualizationData' + urlInfo.postfix,
        getActivityHMapData:            urlInfo.domain+'/getActivityVisualizationData' + urlInfo.postfix,
        getLegend:                      urlInfo.domain+'/getLegendData' + urlInfo.postfix,
        getUnusualEvents:               urlInfo.domain+'/getUnusualEventsData' + urlInfo.postfix,
        getRxDeviceData:                urlInfo.domain+'/getMedicalDeviceDetailData' + urlInfo.postfix,
        getDeviceCategories:            urlInfo.domain+'/getDeviceEventCategories' + urlInfo.postfix,
        getDeviceEventData:             urlInfo.domain+'/getDeviceEventData' + urlInfo.postfix,
        getSensorBatteryLevels:         urlInfo.domain+'/getSensorBatteryLevels' + urlInfo.postfix,
        getHealthReport:                urlInfo.domain+'/getHomeLifeRecord' + urlInfo.postfix,
        keepAlive:                      urlInfo.domain+'/keepAlive' + urlInfo.postfix,

        // App URLS
        root:                        '/',
        patientCardDetails:			 '/home/pDetails',
        goHome:                      '/',
        login:                       '/login',
        patientAlertSetting:         '/home/pDetails/alertSettings',
        heatmapURL:                  '/home/pDetails/hmDisplay',
        unusualEvents:                '/home/pDetails/unusualEvents',
        rxDeviceDetail:              '/home/pDetails/rxDeviceDetail',
        deviceDetail:                '/home/pDetails/deviceDetail',
        batteryLevels:               '/home/pDetails/batteryLevels',
        homeLifeReport:              '/home/pDetails/homeLife',
        validicDeviceSupport:        '/home/validicDeviceSupport',

        // navbar URLS
        caregiverProfile:            '/cgprofile',
        adminProfile:                '/adminprofile',
        about:                       '/about',
        home:                        '/home',
        logout:                      '/logoffUser'
    };

    return _URLS;
}

let URLS = getURLS();

export { URLS };
